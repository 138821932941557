import React, { useContext, useState } from "react"
import { Accordion, Card, Row, Col, Image } from "react-bootstrap"

import sectionStyles from "../../styles/quilmesrock/section.module.css"
import styles from "../../styles/quilmesrock/informacion.module.css"

import border from "../../images/quilmesrock/bottom.png"
import mapa from "../../images/quilmesrock/mapa.png"
import health from "../../images/quilmesrock/health.png"
import calendar from "../../images/quilmesrock/calendar.png"
import map from "../../images/quilmesrock/map.png"
import directions from "../../images/quilmesrock/directions.png"
import help from "../../images/quilmesrock/help.png"
import arrow from "../../images/quilmesrock/downarrow.png"
import card from "../../images/quilmesrock/credit_card.png"

export default function Informacion() {
  const [activeKey, setActiveKey] = useState(0),
    selectKey = key => {
      setActiveKey(activeKey === key ? -1 : key)
      console.log(key)
    }

  return (
    <div name="info" className={styles.containerinfo}>
      <div className={styles.container}>
        <div className={sectionStyles.column}>
          <h4 className={sectionStyles.titulo}> INFORMACIÓN</h4>
          <h2 className={sectionStyles.subtitulo}> INFORMACIÓN DEL EVENTO</h2>
          <img src={border} alt="" />
        </div>
      </div>
      <Accordion defaultActiveKey="" className={styles.accordion}>
        {/* //-------------------------------------------------------------------------- */}
        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className={styles.accordionheader}
          >
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("0")}
            >
              <div>
                <Image src={health} alt="" width={40} className="mr-2" />
                MEDIDAS SANITARIAS
              </div>
              <Image
                src={arrow}
                alt=""
                className={
                  activeKey === "0"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
                width={20}
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <h4 className={styles.font1}>MEDIDAS SANITARIAS</h4>
              <p className={styles.fontLatoBoldItalic}>
                Al día de la fecha es obligatorio contar con pase sanitario. No
                obstante, la normativa referente al COVID cambia con frecuencia,
                por lo que estaremos comunicando la información oficial cercana
                a la fecha del festival. Estate atento a nuestros canales
                oficiales donde estaremos publicando esta información y todo lo
                que necesites saber para disfrutar de la edición 2022 del
                Quilmes Rock.
              </p>
              <p className={styles.fontLatoBoldItalic}>CANALES OFICIALES:</p>
              <p className={styles.fontLatoItalic}>IG @cervezaquilmes</p>
              <p className={styles.fontLatoItalic}>FB: @quilmescerveza</p>
              <a
                href="https://www.areaticket.com.ar/evento/quilmes-rock"
                target="_blank"
              >
                <p className={styles.fontLatoItalic}>
                  https://www.areaticket.com.ar/evento/quilmes-rock
                </p>
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </div>

        {/* //-------------------------------------------------------------------------- */}
        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="1"
            className={styles.accordionheader}
          >
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("1")}
            >
              <div className="d-inline-flex d-md-flex">
                <Image
                  src={calendar}
                  alt=""
                  height={40}
                  width={40}
                  className="mr-2"
                />
                ¿CUÁNDO Y DÓNDE RETIRO MIS ENTRADAS?
              </div>
              <Image
                src={arrow}
                alt=""
                width={20}
                className={
                  activeKey === "1"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
              />
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <p className={styles.fontLatoItalic}>
                Las entradas del Quilmes Rock 2022 son digitales. En base a los
                protocolos de COVID19 la descarga del eTicket se habilitará tres
                días antes de la fecha de la función.
              </p>
              <p className={styles.fontLatoItalic}>Pasos a seguir:</p>

              <p className={styles.fontLatoItalic}>
                • Tres días antes (72 hs según horario de la función), ingresar
                al link del email recibido y hacer clic en el enlace de
                descarga.
              </p>
              <p className={styles.fontLatoItalic}>
                • Inicie sesión con el mismo usuario y clave con el que realizó
                la compra.
              </p>
              <p className={styles.fontLatoItalic}>
                • Completar los datos de la declaración jurada de los asistentes
                al espectáculo.
              </p>
              <p className={styles.fontLatoItalic}>
                • Una vez completado el formulario podrá descargar el eTicket.
              </p>
              <p className={styles.fontLatoItalic}>
                Para modificar los datos de uno o varios asistentes, deberá
                ingresar a su cuenta en el apartado MIS COMPRAS hasta 3 hs antes
                del horario de la función.
              </p>
              <p className={styles.fontLatoBoldItalic}>
                RECORDÁ CARGAR TU TELÉFONO ANTES DE ASISTIR AL EVENTO; COMO
                ALTERNATIVA, PODES LLEVAR EL E-TICKET IMPRESO.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </div>


        {/* //-------------------------------------------------------------------------- */}
        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="2"
            className={styles.accordionheader}
          >
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("2")}
            >
              <div>
                <Image src={map} alt="" width={40} className="mr-2" />
                UBICACIÓN DEL PREDIO
              </div>
              <Image
                src={arrow}
                alt=""
                width={20}
                className={
                  activeKey === "2"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <p className={styles.fontLatoItalic}>
                    El predio de Tecnópolis está ubicado en J.B. de la Salle
                    4400, Villa Martelli, Vicente López. En pocos días estaremos
                    compartiendo el plano del predio con el detalle de accesos
                    tanto peatonales como para vehículos, motos y bicicletas.
                    Esté atento a nuestros canales oficiales donde
                    actualizaremos dicha información.
                  </p>
                  <p className={styles.fontLatoBoldItalic}>
                    CANALES OFICIALES:
                  </p>
                  <p className={styles.fontFirstRed}>IG @cervezaquilmes</p>
                  <p className={styles.fontRed}>FB: @quilmescerveza</p>
                  <p className={styles.fontEndRed}>LINK A MICROSITIO</p>
                </Col>
                <Col xs={12} md={6}>
                  <a
                    href="https://goo.gl/maps/G8symknvVQkNS5BZ6"
                    target="_blank"
                  >
                    <Image fluid src={mapa} alt="" />
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </div>
        {/* //-------------------------------------------------------------------------- */}
        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="3"
            className={styles.accordionheader}
          >
            {" "}
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("3")}
            >
              <div className="d-inline-flex d-md-flex">
                <Image
                  src={directions}
                  alt=""
                  height={40}
                  width={40}
                  className="mr-2"
                />
                MEDIOS PARA LLEGAR A TÉCNOPOLIS
              </div>
              <Image
                src={arrow}
                alt=""
                width={20}
                className={
                  activeKey === "3"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
              />{" "}
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <p className={styles.fontLatoBoldItalic}>
                    El predio de Tecnópolis está ubicado en Villa Martelli,
                    Vicente López, sobre la Av. General Paz.
                  </p>
                  <ul>
                    <li className={styles.fontLatoBoldItalic}>
                      Vení en colectivo:Av. General Paz (Ingreso principal)
                      Líneas: 21, 28, 117 AV. de los Constituyentes 2200
                      (Ingreso 2) 111, 140, 110, 175, 127, 175, 176
                    </li>
                    <li className={styles.fontLatoBoldItalic}>
                      Vení en tren: FF.CC. MITRE: Ramal José Suarez - Estación
                      Migueletes FF.CC BELGRANO: Estación Padilla FF.CC MITRE:
                      Ramal Mitre - Estación J.B.Justo (Después tomar un
                      colectivo por Av. Gral Paz).
                    </li>
                    <li className={styles.fontLatoBoldItalic}>
                      Vení en bici: El predio cuenta con estacionamiento para
                      bicis y motos.
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <a
                    href="https://goo.gl/maps/G8symknvVQkNS5BZ6"
                    target="_blank"
                  >
                    <Image fluid src={mapa} alt="" />
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </div>


        {/* //-------------------------------------------------------------------------- */}
        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="5"
            className={styles.accordionheader}
          >
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("5")}
            >
              <div className="d-inline-flex d-md-flex">
                <Image
                  src={card}
                  alt=""
                  height={40}
                  width={40}
                  className="mr-2"
                />
                ¡CONOCÉ NUESTRA TARJETA CASHLESS!
              </div>
              <Image
                src={arrow}
                alt=""
                width={20}
                className={
                  activeKey === "5"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
              />
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <p className={styles.fontLatoItalic}>
                Todos los consumos que se realicen dentro del festival se harán
                a través de la Tarjeta Cashless Quilmes Rock. La misma será
                válida para los dos días del festival.
              </p>
              <p className={styles.fontLatoItalic}>
                La tarjeta la obtendrás únicamente dentro del predio, donde
                también deberás cargarla y validarla en los puestos de carga
                (Cajas).
              </p>
              <p className={styles.fontLatoItalic}>
                Podrás cargarle dinero en efectivo, tarjeta de crédito/debito o
                mediante Mercado Pago. En caso de elegir Mercado pago, podrás
                hacerlo escaneando el QR en el frente de la tarjeta y luego
                deberás validar la carga con algún asistente de validación que
                se encontrarán por la zona de los puestos de carga (Cajas).
              </p>
              <p className={styles.fontLatoItalic}>
                Una vez finalizado el evento, en caso de que te haya sobrado
                dinero cargado, podrás solicitar el reintegro. Para ello presta
                atención a lo siguiente:
              </p>
              <br />
              <p className={styles.fontLatoItalic}>
                • CARGAS CON MERCADO PAGO (QR)
                <br />
                La solicitud de devolución debe realizarse entre el 2/5 y el 4/5
                mediante el registro en el siguiente link:
                <br />
                <a
                  href=" https://ego.easygoband.com/#/app/7d4c7ec9-930e-4f6f-b27b-969123bc030f/signup"
                  alt="link devolucion efectivo"
                  target="_blank"
                >
                  https://ego.easygoband.com/#/app/7d4c7ec9-930e-4f6f-b27b-969123bc030f/signup
                </a>
                <br />
                Si cargaste tu tarjeta con Mercado pago, deberás acceder al link
                anterior y completar con los datos solicitados. Una vez
                finalizado el formulario, el reintegro se confirmara de 1 a 15
                días desde el día del registro.
                <br />
                *La demora en ver el monto reflejado en las cuentas de cada
                usuario puede variar en función de los cierres de cada
                banco/tarjeta.
              </p>
              <br />
              <p className={styles.fontLatoItalic}>
                • CARGAS CON POINT (TARJETAS CREDITO/DEBITO)
                <br />
                La solicitud de devolución debe realizarse entre el 5/5 y el 7/5
                mediante el registro en el siguiente link:
                <br />
                <a
                  href="https://d3mwfhutidl2wt.cloudfront.net/app/7d4c7ec9-930e-4f6f-b27b-969123bc030f"
                  alt="link devolucion tarjeta"
                  target="_blank"
                >
                  {" "}
                  https://d3mwfhutidl2wt.cloudfront.net/app/7d4c7ec9-930e-4f6f-b27b-969123bc030f{" "}
                </a>
                <br />
                Si cargaste tu tarjeta con tarjeta de crédito o débito, deberás
                acceder al link anterior y completar con los datos solicitados.
                Una vez finalizado el formulario el reintegro se realizará de 1
                a 30 días desde el día del registro.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </div>

        {/* //-------------------------------------------------------------------------- */}

        <div>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="4"
            className={styles.accordionheader}
          >
            {" "}
            <div
              className={styles.accordioncardheader}
              onClick={() => selectKey("4")}
            >
              <div>
                <Image src={help} alt="" width={40} className="mr-2" /> FAQs
              </div>
              <Image
                src={arrow}
                alt=""
                width={20}
                className={
                  activeKey === "4"
                    ? styles.flechaactiva
                    : styles.flechainactiva
                }
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <h4 className={styles.font1}>Preguntas frecuentes:</h4>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿A qué hora aprox. empieza y termina?
                <br />
                El festival es en parte diurno y en parte nocturno. Recomendamos
                reservarse el día desde temprano. En breve estaremos comunicando
                los horarios.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Existe descuento para discapacitados?
                <br />
                No.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Se puede ir en silla de ruedas?
                <br />
                Si.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Se puede ingresar con comida?
                <br />
                No.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Con que se puede ingresar?
                <br />
                NO permitidos: Armas de fuego o explosivos, elementos punzantes,
                paraguas, líquidos o gases inflamables, heladeras, animales,
                aerosoles, selfie stick, drones, alimentos o bebidas.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Es con pulsera?
                <br />
                No, es E-ticket, el mismo debería haber sido recibido en el mail
                de confirmación post compra. Es único e intransferible. Al
                acceder al festival, no se puede salir y reingresar. Una vez que
                el código es validado, el mismo deja de funcionar.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿Se puede salir y volver a entrar?
                <br />
                El E-ticket es único e intransferible. Al acceder al festival,
                no se puede salir y reingresar. Una vez que el código es
                validado, el mismo deja de funcionar.
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿El campo está muy atrás?
                <br />
                La visibilidad es buena desde todos los ángulos, la idea es que
                todxs puedan vivir un Quilmes Rock inolvidable. Los escenarios
                Quilmes Rock cuentan con un área de Campo Fan, un espacio
                diseñado para vivir la experiencia más cerca de tus ídolos. Para
                el resto de los escenarios si queres estar en primera fila tenes
                que organizarte y llegar temprano al horario de tu banda
                preferida. ¡No cuelgues!
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - ¿El campo fan es en todos los escenarios?
                <br />
                Los escenarios Quilmes Rock cuentan con un área de Campo Fan, un
                espacio diseñado para vivir la experiencia más cerca de tus
                ídolos. Para el resto de los escenarios si queres estar en
                primera fila tenes que organizarte y llegar temprano al horario
                de tu banda preferida. ¡No cuelgues!
              </p>
              <p className={styles.fontLatoBoldItalicP}>
                - Si compré campo fan, ¿Puedo ir al campo común?
                <br />
                Si. Acceden a los mismos sectores y a algunos adicionales.
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  )
}
