import React, { useState, useEffect } from "react"
import { Button, Col, Container, Image, Row } from "react-bootstrap"
import { NavLink } from "react-bootstrap"
import { Link } from "react-scroll"
import { useMediaQuery } from "react-responsive"
import { IsMobileDevice } from "../../../utils/ismobile"

import logo from "../../images/quilmesrock/logo.png"
import banner from "../../images/quilmesrock/banner.png"
import arrow from "../../images/quilmesrock/arrow.svg"
import logoMenu from "../../images/logos/logoMenu.svg"

import styles from "../../styles/quilmesrock/hero.module.css"
import SidebarRock from "./SidebarRock"

export default function Hero() {
  const [isMobile, setIsMobile] = useState(false)
  const mobile = useMediaQuery({ query: "(max-width: 767px)" }),
    [show, setShow] = useState(true)

    useEffect(() => {
      setIsMobile(IsMobileDevice())
    }, [])

  return (
    <div className={styles.container} name="home">
      {isMobile ? (
        <header className={styles.headermobile}>
          <div />
          <img src={logo} alt="Quilmes rock" />
          <img
            className={styles.menuIcon}
            src={logoMenu}
            onClick={() => setShow(false)}
            alt="menuIcon"
          />
        </header>
      ) : (
        <header className={styles.header}>
          <Link to="home" smooth={true}>
            HOME{" "}
          </Link>
          <Link to="lineup" smooth={true}>
            LINEUP{" "}
          </Link>
          <Link to="info" smooth={true}>
            INFORMACIÓN{" "}
          </Link>
          <Link>
            <img src={logo} alt="Quilmes rock" />
          </Link>
          <Link to="sponsors" smooth={true}>
            SPONSORS
          </Link>
          <Link to="tickets" smooth={true}>
            TICKETS{" "}
          </Link>
          <Link to="promociones" smooth={true}>
            NOVEDADES{" "}
          </Link>
        </header>
      )}

      <SidebarRock setActiveMenu={setShow} activeMenu={show} />
      <Container>
        <div className={styles.banner}>
          <Image src={banner} fluid alt="" />
        </div>
      </Container>

      <Container className={styles.botonera}>
        <Row className="justify-content-center align-items-center">
          {/* <Col xs={4} md={2}>
            <Link to="lineup" smooth={true}>
              <Button className={styles.boton}>LINEUP</Button>
            </Link>
          </Col> */}
          {/* <Col xs={1} md={1}> */}
          <a
            href="https://ventas.areaticket.com.ar/event?p=82B84FBBA982F19E7529DDFDDAD726A767B86BDFD1504539&utm_source=quakmedia&utm_medium=google%20ads&utm_campaign=quilmes%20rock&gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqOGYHL3-QPDrx2Xq04gGgUfvxWisFdle3DoywkEyV22qxnLTi1Bzg0aAvueEALw_wcB"
            className="text-decoration-none"
          >
            <Button className={styles.boton}>TICKETS</Button>
          </a>
          {/* </Col> */}
        </Row>
      </Container>

      <div className={styles.scrollero}>
        <p>SCROLEA Y ENTERATE DE TODO</p>
        <img alt="" src={arrow} />
      </div>
    </div>
  )
}
