import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap";

import styles from "../../styles/quilmesrock/tickets.module.css"
import sectionStyles from "../../styles/quilmesrock/section.module.css"

import border from "../../images/quilmesrock/bottom.png"
import ticket1 from "../../images/quilmesrock/ticket1.png"
import ticket2 from "../../images/quilmesrock/ticket2.png"

export default function Tickets() {
  return (
    <div className={sectionStyles.container} name="tickets">
      <div className={sectionStyles.column}>
        <h4 className={sectionStyles.titulo}>TICKETS</h4>
        <h2 className={sectionStyles.subtitulo}>CONSEGUÍ TU TICKET</h2>
        <img src={border} alt="" />
      </div>
      <div className={styles.tickets}>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <a href="https://ventas.areaticket.com.ar/default?p=82B84FBBA982F19E7529DDFDDAD726A767B86BDFD1504539&utm_source=quakmedia&utm_medium=google%20ads&utm_campaign=quilmes%20rock&gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqOGYHL3-QPDrx2Xq04gGgUfvxWisFdle3DoywkEyV22qxnLTi1Bzg0aAvueEALw_wcB" className="text-decoration-none"><Image fluid src={ticket1} alt="" className={styles.ticketsimg} /></a>
            </Col>
            <Col xs={12} md={6}>
              <a href="https://ventas.areaticket.com.ar/default?p=82B84FBBA982F19E7529DDFDDAD726A767B86BDFD1504539&utm_source=quakmedia&utm_medium=google%20ads&utm_campaign=quilmes%20rock&gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqOGYHL3-QPDrx2Xq04gGgUfvxWisFdle3DoywkEyV22qxnLTi1Bzg0aAvueEALw_wcB" className="text-decoration-none"> <Image fluid src={ticket2} alt="" className={styles.ticketsimg} /></a>
            </Col>
          </Row>
        </Container>

      </div>
    </div>
  )
}
