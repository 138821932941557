import React, { useState, useEffect } from "react"
import { Button, Modal, Form } from "react-bootstrap"
import Promociones from "./PromocionesModal"

const ModalForm = ({ handleShow, handleClose, show }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Promociones handleClose={handleClose} />
      </Modal>
    </>
  )
}
export default ModalForm
